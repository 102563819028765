var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.edit
      ? _c(
          "div",
          { staticClass: "d-flex flex-align-center py-auto" },
          [
            _vm._t("default"),
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    _vm.edit = true
                  }
                }
              },
              [_c("v-icon", [_vm._v("mdi-account-edit")])],
              1
            )
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "d-flex mt-8" },
          [
            _c("v-select", {
              attrs: {
                solo: "",
                dense: "",
                light: "",
                label: "Screener",
                placeholder: "Select Screener",
                color: "primary",
                items: _vm.items,
                value: _vm.value,
                "item-text": "name",
                "item-value": "id",
                "return-object": ""
              },
              on: { change: _vm.selectItem }
            }),
            _c(
              "v-btn",
              {
                staticClass: "mt-n1",
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.edit = false
                  }
                }
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }