<template>
    <!-- TODO: turn this into a generic component -->
    <div>
        <div v-if="!edit" class="d-flex flex-align-center py-auto">
            <slot></slot>
            <!-- Only show the edit icon if we are not currently editing -->
            <v-btn icon @click="edit=true">
                <v-icon>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <div v-else class="d-flex mt-8">
            <v-select
                solo
                dense
                light
                label="Screener"
                placeholder="Select Screener"
                color="primary"
                :items="items"
                :value="value"
                item-text="name"
                item-value="id"
                return-object
                @change="selectItem"
            ></v-select>
            <v-btn icon @click.stop="edit=false" class="mt-n1">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    name: "user-select",
    props: {
        items: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            edit:
                this.items.find((val) => val.id == this.value.id) == undefined,
        };
    },
    methods: {
        selectItem(item) {
            this.$emit("input", item);
            this.edit = false;
        },
    },
};
</script>

<style scoped>
</style>